<template>
  <show-more-block
    :show-more="showMore"
    :update-list="updateArticles"
    :sort-type="sortType"
    :sort="sort"
    :page="page"
    :init-size="articles.length + 1"
  >
    <div class="inner-news-tovar">
      <article-tile :tile="headArticle" />
      <article-tiles :tiles="articles" />
    </div>
    <div class="clear"></div>
  </show-more-block>
</template>

<script>
import ArticleTiles from "@/components/page/articles/tiles/ArticleTiles";
import ArticleTile from "@/components/page/articles/tiles/ArticleTile";
import ShowMoreBlock from "@/components/page/items/list/ShowMoreBlock";
export default {
  name: "ArticlesMediaBlock",
  components: { ShowMoreBlock, ArticleTile, ArticleTiles },
  props: {
    articles: Array,
    mainArticle: [Array, Object],
    showMore: {
      type: Boolean,
      default: true
    },
    updateArticles: Function,
    sort: {
      type: String,
      default: ""
    },
    sortType: {
      type: String,
      default: ""
    },
    page: {
      type: Number,
      default: 1
    }
  },
  computed: {
    headArticle() {
      return { ...this.mainArticle[0], isFull: true };
    }
  }
};
</script>

<style scoped></style>
