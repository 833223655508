<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-big">Статьи</h1>
      <media-filter-block
        :show-filter="false"
        :sort-types="sortTypes"
        :sort-method="loadArticles"
      />
      <articles-media-block
        :sort-type="sortType"
        :sort="sort"
        :articles="anarchyArticles(headArticle)"
        :main-article="monarchyArticles(headArticle)"
        :page="page"
        :update-articles="loadArticles"
      />
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import MediaFilterBlock from "@/components/page/media/MediaFilterBlock";
import ArticlesMediaBlock from "@/components/page/articles/ArticlesMediaBlock";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Articles",
  components: { MediaFilterBlock, ArticlesMediaBlock },
  data() {
    return {
      headArticle: 1,
      page: 1,
      size: 16,
      sort: "",
      sortType: "",
      sortTypes: [
        { topic: "POPULAR", dir: "DESC", page: 1, name: "По популярности" },
        { topic: "NEW", dir: "DESC", page: 1, name: "По новизне" },
        { topic: "RATING", dir: "DESC", page: 1, name: "По рейтингу" }
      ]
    };
  },
  computed: {
    ...mapGetters(["anarchyArticles", "monarchyArticles"])
  },
  methods: {
    ...mapActions(["getArticles"]),
    loadArticles(sort, direction, page) {
      if (sort && direction) {
        this.sort = sort;
        this.sortType = direction;
      }
      if (page) {
        this.page = page;
      }
      return this.getArticles({
        page: this.page,
        size: this.size,
        sort: sort,
        sortType: direction
      });
    }
  },
  mounted() {
    new WOW({ offset: 140 }).init();
  },
  created() {
    this.loadArticles().then(data => {
      this.headArticle = data[0].id;
    });
  }
};
</script>

<style scoped></style>
