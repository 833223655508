<template>
  <div class="inner-states-list" v-if="tiles && tiles.length">
    <article-tile v-for="tile in tiles" :tile="tile" v-bind:key="tile.key" />
  </div>
</template>

<script>
import ArticleTile from "@/components/page/articles/tiles/ArticleTile";
export default {
  name: "ArticleTiles",
  components: { ArticleTile },
  props: {
    tiles: Array
  }
};
</script>

<style scoped></style>
